@import "vars.scss";
@import "login.scss";
@import "nav.scss";
@import "tables.scss";

html,
body {
    height: 100%;
}

.number-input {
    text-align: right;
    width: 3.5rem;
}

@media (min-width: 768px) {
    .wrapper {
        margin-left: $sidebar-width;
    }
}

/* 
 * Hide controls when input type="number"
 * https://stackoverflow.com/a/26379166/286611
 */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}