/*
 * Highly inspired by Start Bootstrap - SB Admin v4.0.0-alpha (http://startbootstrap.com/template-overviews/sb-admin)
 */

$white:  #fff !default;
$navbar-dark-color:                 rgba($white,.5) !default;
$navbar-dark-hover-color:           rgba($white,.75) !default;
$navbar-dark-active-color:          rgba($white,1) !default;

/* From bootstrap 4-beta */
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$black:  #000 !default;
$navbar-light-color:                rgba($black,.5) !default;
$navbar-light-hover-color:          rgba($black,.7) !default;
$navbar-light-active-color:         rgba($black,.9) !default;

$nav-padding-size: 1em;

@mixin nav-a-padding {
    padding-left: $nav-padding-size;
    padding-right: $nav-padding-size;
}

#nav {
    .navbar-collapse form {
        padding-top: $nav-padding-size;
        padding-bottom: $nav-padding-size;
        
        .input-group {
            width: 100%;
        }
    }

    .sidebar {
        .badge {
            position: absolute;
            right: 35px;
            margin-top: 0.15em;
        }
    
        .nav-link-collapse:after {
            font-family: 'FontAwesome';
            content: '\f107';
            float: right;
        }
        
        .nav-link-collapse.collapsed:after {
            content: '\f105';
        }
        
        .sidebar-second-level,
        .sidebar-third-level {
            padding-left: 0;
            list-style: none;
            
            > li > a {
                display: block;
                padding: .5em 0;
                color: $navbar-dark-color;
                
                &:focus,
                &:hover {
                    text-decoration: none;
                    color: $navbar-dark-hover-color;
                }
                
                &.active {
                    color: $navbar-dark-active-color;
                }
            }
        }
        
        .sidebar-second-level > li > a {
            padding-left: 1em;
        }
        
        .sidebar-third-level > li > a {
            padding-left: 2em;
        }
    }

    @media (min-width: 768px) {
        /*.navbar-brand {
            width: $sidebar-width + 0.5rem;
        }*/
        
        .navbar-collapse form {
            padding-top: 0;
            padding-bottom: 0;
            
            .input-group {
                width: 25em;
            }
        }
    
        .sidebar {
            position: absolute;
            top: 0;
            left: 0;
            overflow: auto;
            flex-direction: column;
            height: 100vh;
            margin-top: 56px;
            /*background-color: $gray-100;*/
            border-right: 1px solid $gray-200;
            
            > .nav-item {
                width: $sidebar-width;
                
                > form {
                    padding: $nav-padding-size;
                }
            
                > .nav-link {
                    @include nav-a-padding;
                }
                
                .sidebar-second-level,
                .sidebar-third-level {
                    > li > a {
                        @include nav-a-padding;
                        color: $navbar-light-color;
                        
                        &:focus,
                        &:hover {
                            color: $navbar-light-hover-color;
                        }
                        
                        &.active {
                            color: $navbar-light-active-color;
                        }
                    }
                }
                
                .sidebar-second-level > li > a {
                    padding-left: 2em;
                }
                
                .sidebar-third-level > li > a {
                    padding-left: 3em;
                }
            }
            
            li > a {
                color: $navbar-light-color;
            
                &:focus,
                &:hover {
                    color: $navbar-light-hover-color;
                    background-color: $gray-200;
                }
                
                &.active {
                    color: $navbar-light-active-color;
                }
            }
            
            li.active a.active {
                background-color: $gray-200;
            }
        }
    }
}