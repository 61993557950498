#login {
    background: #eee;
    margin-top: 1rem;
    
    .signin {
        max-width: 20rem;
        margin: auto;
    }
    
    .form-signin {
        margin-bottom: 1rem;
    }
    
    .form-signin-heading {
        margin-bottom: 0.7rem;
    }
    
    input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    input[type="password"] {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
    
    @media (min-width: 992px) {
        margin-top: 3.1rem;
    }
}